import React, { FC } from 'react';
import {
  ListItemAvatar as MuiListItemAvatar,
  ListItemAvatarProps as MuiListItemAvatarProps,
} from '@material-ui/core';

export interface ListItemAvatarProps extends MuiListItemAvatarProps {}

export const ListItemAvatar: FC<ListItemAvatarProps> = ({
  children,
  ...props
}) => {
  return <MuiListItemAvatar {...props}>{children}</MuiListItemAvatar>;
};
