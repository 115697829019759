import React, { FC } from 'react';
import {
  ListItemSecondaryAction as MuiListItemSecondaryAction,
  ListItemSecondaryActionProps as MuiListItemSecondaryActionProps,
} from '@material-ui/core';

export interface ListItemSecondaryActionProps
  extends MuiListItemSecondaryActionProps {}

export const ListItemSecondaryAction: FC<ListItemSecondaryActionProps> = ({
  children,
  ...props
}) => {
  return (
    <MuiListItemSecondaryAction {...props}>
      {children}
    </MuiListItemSecondaryAction>
  );
};
